<template>
  <div class="phone-number-container align-items-center">
    <div class="d-flex w-100">
      <div class="w-100 mr-2">
        <BaseInput
          v-if="is_confirm"
          v-model="otpInput"
          :errorMessage="otpError"
          :isInvalid="$v.otpInput.$error"
          :label="$t('fields.otp.label')"
          :placeholder="$t('fields.otp.placeholder')"
        />
        <BaseInput
          v-else
          v-model="phoneInput"
          :countryCode="user.country.calling_code"
          :disabled="!editable"
          :errorMessage="phoneNumberError"
          :isInvalid="editable && $v.phoneInput.$error"
          :isPhoneInput="true"
          :label="$t('transaction.phone_number')"
          :placeholder="$t('transaction.phone_number_placeholder')"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </div>
      <div v-if="editable" class="confirm-phone">
        <button
          :disabled="$v.$invalid || loading"
          class="be-btn secondary blue circle-btn lg"
          role="button"
          type="button"
          :title="btnLabel"
          @click.prevent="changePhoneNumber"
        >
          <i class="icon-success" />
        </button>
      </div>
    </div>
    <div v-if="is_confirm" class="d-flex w-100">
      <a
        class="text-success d-block"
        href="#"
        :title="btnLabel"
        @click.prevent="resendConfirmationCode"
      >
        <em v-if="counter > 0">
          {{ $t("utils.new_code_in") }} {{ counter }} s</em
        >
        <em v-else>{{ $t("utils.send_code") }}</em>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  maxLength,
  minLength,
  required,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  name: "UserPhoneNumber",
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      is_confirm: false,
      phoneInput: null,
      otpInput: null,
      counter: 180,
      error: null,
      codeInterval: null,
      otpId: null,
    };
  },

  created() {
    this.phoneInput = this.user.phone;
    this.$v.$touch();
  },

  computed: {
    ...mapState("auth", ["user"]),
    btnLabel() {
      return this.is_confirm
        ? this.$t("common.send")
        : this.$t("common.confirm");
    },
    phoneNumberError() {
      if (this.$v.phoneInput.$error || this.error) {
        if (this.error) {
          return this.error;
        }
        if (!this.$v.phoneInput.required) {
          return this.$t("utils.required.number");
        }
        if (!this.$v.phoneInput.minLength || !this.$v.phoneInput.maxLength) {
          return (
            this.$t("utils.required.numbers_length_before") +
            ` ${this.user.country.phone_length} ` +
            this.$t("utils.characters")
          );
        }
      }
      return "";
    },
    otpError() {
      if (this.$v.otpInput.$error || this.error) {
        if (this.error) {
          return this.error;
        }
        if (!this.$v.otpInput.required) {
          return this.$t("utils.required.number");
        }
      }
      return "";
    },
  },

  methods: {
    ...mapActions("auth", [
      "sendPhoneConfirmationCode",
      "confirmPhoneNumber",
      "resendPhoneConfirmationCode",
    ]),

    changePhoneNumber() {
      if (this.is_confirm) {
        if (!this.$v.otpInput.$error) {
          this.confirmPhoneNumber({ otp: this.otpInput })
            .then(() => {
              clearInterval(this.codeInterval);
              this.is_confirm = false;
              this.error = null;
            })
            .catch(err => {
              if (err.response.data.message)
                this.error = err.response.data.message;
              this.code = null;
            });
        }
      } else {
        if (!this.$v.phoneInput.$error) {
          this.sendPhoneConfirmationCode({ phone: this.phoneInput })
            .then(({ data }) => {
              this.error = null;
              this.is_confirm = true;
              this.otpId = data.id;
              this.codeInterval = setInterval(() => {
                if (this.counter > 1) this.counter--;
                else {
                  clearInterval(this.codeInterval);
                  this.counter = 0;
                }
              }, 1000);
            })
            .catch(err => {
              if (err.response.data.message)
                this.error = err.response.data.message;
            });
        }
      }
    },

    resendConfirmationCode() {
      if (this.is_confirm && this.counter <= 0 && this.otpId) {
        this.resendPhoneConfirmationCode(this.otpId).then(() => {
          this.codeInterval = setInterval(() => {
            if (this.counter > 1) this.counter--;
            else {
              clearInterval(this.codeInterval);
              this.counter = 0;
            }
          }, 1000);
        });
      }
    },
  },

  validations() {
    return {
      phoneInput: {
        required,
        minLength: minLength(this.user.country.phone_length),
        maxLength: maxLength(this.user.country.phone_length),
      },
      otpInput: {
        required: requiredIf(this.is_confirm),
      },
    };
  },

  beforeDestroy() {
    clearInterval(this.codeInterval);
  },
};
</script>

<style scoped></style>
