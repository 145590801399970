<template>
  <div class="h-100 position-lg-absolute">
    <h4 class="profile-divider with-divider blue f-w-400">
      {{ $t("profile.personal_information") }}
    </h4>
    <form class="form h-100" @submit.prevent="handleEditProfile">
      <be-form-error v-if="error" :message="error" />
      <div class="form-container overflow-auto h-100 be-scroll">
        <div class="be-row">
          <div class="be-col">
            <div>
              <BaseInput
                v-model="profile.last_name"
                :errorMessage="lastNameErrorMessage"
                :label="$t('common.name')"
                :placeholder="$t('common.name_placeholder')"
                iconClass="icon-menu-open"
                type="text"
                @blur="$v.profile.last_name.$touch()"
              >
              </BaseInput>
            </div>
          </div>
          <div class="be-col">
            <div>
              <BaseInput
                v-model="profile.first_name"
                :errorMessage="firstNameErrorMessage"
                :label="$t('common.surname')"
                :placeholder="$t('common.surname_placeholder')"
                iconClass="icon-menu-open"
                type="text"
                @blur="$v.profile.first_name.$touch()"
              ></BaseInput>
            </div>
          </div>
        </div>
        <div class="be-row">
          <div class="be-col">
            <div>
              <BeMultiselect
                v-model="profile.gender"
                :errorMessage="genderErrorMsg"
                :initialValue="{ value: profile.gender, name: profile.gender }"
                :options="[
                  { value: 'M', name: 'Masculin' },
                  { value: 'F', name: 'Féminin' },
                ]"
                icon="icon-user-fill"
                inputLabel="Gender"
                label="name"
                onlyKey
                trackBy="value"
              />
            </div>
          </div>
          <div class="be-col">
            <!-- <div class="be-col-label">? -->
            <div>
              <BaseDatepicker
                v-model="profile.birthday"
                :is-invalid="
                  $v.profile.birthday.$error || errors.birthday !== undefined
                "
                :label="$t('common.birth_date')"
                :not-after="dateNotAfter"
                :placeholder="$t('common.choose_date')"
                @blur="$v.profile.birthday.$touch()"
              ></BaseDatepicker>
              <template v-if="$v.profile.birthday.$error || errors.birthday">
                <span class="text-danger d-block text-left f-12 mt-1">
                  <span v-if="!$v.profile.birthday.required">
                    {{ $t("common.insert_birth_date") }}
                  </span>
                  <span v-if="errors.birthday">
                    {{ errors.birthday[0] }}
                  </span>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div class="be-row">
          <div class="be-col">
            <BeMultiselect
              v-model="profile.country"
              :disabled="!isCurrentUserMerchant"
              :initialValue="profile.country"
              :options="countries"
              inputLabel="Pays"
              label="name"
              trackBy="id"
              v-bind:class="{ disabled: !isCurrentUserMerchant }"
            />
          </div>
          <div class="be-col">
            <UserPhoneNumber :editable="isEditablePhone" />
          </div>
        </div>
      </div>
      <div class="button-container">
        <div class="divider"></div>
        <BaseButton
          :disabled="$v.$invalid || !editing"
          :loading="loading"
          :show-loading="true"
          buttonClass="be-btn primary lg blue"
        >
          {{ $t("common.save") }}
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import UserPhoneNumber from "../../components/profile/UserPhoneNumber";
import { required } from "vuelidate/lib/validators";
import BeMultiselect from "../../components/common/BeMultiselect";
import BeFormError from "../../components/common/BeFormError";

export default {
  name: "FormProfile",
  components: { UserPhoneNumber, BeMultiselect, BeFormError },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isCurrentUserMerchant", "isCompleteProfile"]),
    ...mapState(["countries"]),
    lastNameErrorMessage() {
      if (this.$v.profile.last_name.$error || this.errors.last_name) {
        if (!this.$v.profile.last_name.required) {
          return this.$t("common.insert_name");
        }
        if (this.errors.last_name) {
          return this.errors.last_name[0];
        }
      }
      return "";
    },
    firstNameErrorMessage() {
      if (this.$v.profile.first_name.$error || this.errors.first_name) {
        if (!this.$v.profile.first_name.required) {
          return this.$t("common.insert_surname");
        }
        if (this.errors.first_name) {
          return this.errors.first_name[0];
        }
      }
      return "";
    },
    isEditablePhone() {
      return (
        this.user?.phone_verified_at === null ??
        (this.isCompleteProfile && this.isCurrentUserMerchant)
      );
    },
    dateNotAfter() {
      let d = new Date();
      d.setFullYear(d.getFullYear() - 18);
      return d;
    },
    genderErrorMsg() {
      if (this.$v.profile.gender.$error || this.errors.gender) {
        if (this.$v.profile.gender.required) {
          return this.$t("profile.required.gender");
        }
        if (this.errors.gender) {
          return this.errors.gender[0];
        }
      }
      return "";
    },
  },
  watch: {
    profile: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && oldValue.country_id) this.editing = true;
        this.profile.country_id = newValue.country.id;
      },
    },
  },

  data() {
    return {
      loading: false,
      editing: false,
      profile: {
        country_id: null,
        last_name: null,
        first_name: null,
        gender: null,
        birthday: null,
      },
      errors: [],
      error: null,
    };
  },

  created() {
    this.profile.country = this.user.country;
    this.profile.country_id = this.user.country.id;
    this.profile.last_name = this.user.last_name;
    this.profile.first_name = this.user.first_name;
    this.profile.gender = this.user.gender;
    this.profile.birthday = this.user.birthday;
  },

  methods: {
    handleEditProfile() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        this.errors = [];
        this.$store
          .dispatch("auth/updateUser", this.profile)
          .then(() => {
            location.reload();
          })
          .catch(err => {
            if (err.errors) this.errors = err.errors;
            if (err.message) {
              this.error = err.message;
              if (this.errors["email"]) this.error = this.errors["email"][0];
            }
            this.loading = false;
          });
      }
    },
  },

  validations: {
    profile: {
      country_id: { required },
      last_name: { required },
      first_name: { required },
      gender: { required },
      birthday: { required },
    },
  },
};
</script>

<style scoped></style>
